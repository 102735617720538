<template>
  <div class="box">
    <div class="image" @click="$router.push('/')">
      <img src="../assets/image/logo.png" alt="" />
    </div>
    <div class="title">优质教育资源激活中心</div>
    <div class="content">
      <div>
        <div style="text-align: center; font-weight: 700">
          ------ 欢 迎 激 活 ------
        </div>
        <div>
          <el-input
            clearable
            placeholder="账号"
            prefix-icon="el-icon-user"
            v-model="input3"
            @input="cardLogin"
            @blur="cardLogin"
          >
          </el-input>
          <div class="tip" v-if="isCard">{{ tips3 }}</div>
          <div style="height: 12px" v-else></div>
        </div>

        <div>
          <el-input
            clearable
            placeholder="密码"
            v-model="input4"
            @blur="cardpwdLogin"
            @input="cardpwdLogin"
            type="password"
            prefix-icon="el-icon-lock"
          >
          </el-input>
          <div class="tip" v-if="isCardPwd">{{ tips4 }}</div>
          <div style="height: 12px" v-else></div>
        </div>
        <div style="text-align: center">
          <el-button type="primary" round class="btnlogin" @click="handleLogin2"
            >确认激活</el-button
          >
          
        </div>
        <div
          style="
            font-size: 12px;
            text-align: center;
            margin-top: 30px;
            color: #7b7b7b;
          "
        >
          <span style="color: red">注意:</span>
          账号密码为您资源卡的账号和密码，请保管好您的账号和密码，不要轻易透露 !
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import URL from "../api/index";
export default {
  data() {
    return {
      // 账号
      input3: "",
      input4: "",
      tips3: "请输入账号",
      tips4: "请输入密码",
      isCard: false,
      isCardPwd: false,
    };
  },
  components: {},
  mounted() {},
  methods: {
    // 卡号 账号
    cardLogin() {
      if (!this.input3) {
        this.isCard = true;
      } else {
        this.isCard = false;
      }
    },
    // 卡号 密码
    cardpwdLogin() {
      if (!this.input4) {
        this.isCardPwd = true;
      } else {
        this.isCardPwd = false;
      }
    },
    // 优质资源卡激活中心
    handleLogin2() {
      var data = {
        token: JSON.parse(localStorage.getItem("loginInfo")).token,
        type: 1,
        ma_id: this.input3,
        ma_key: this.input4,
      };
      if (!this.input3) {
        this.isCard = true;
      }
      if (!this.input4) {
        this.isCardPwd = true;
      }
      if (!this.isCard && !this.isCardPwd) {
        this.$post(URL.getcardActivate, data).then((res) => {
          if (res.state == 0) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            localStorage.setItem("vip", 1);
            this.$router.push("/");
          } else {
            this.$message(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang='less' scoped>
.box {
  height: 100%;
  padding: 0;
  background: url(../assets/image/img.png) no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  .tip {
    margin-left: 32px;
    height: 12px;
    font-size: 12px;
    color: red;
  }
  .image {
    cursor: pointer;
    width: 1100px;
    padding-top: 50px;
    margin: 0 auto;
    img {
      width: 100px;
    }
  }
  .title {
    margin-top: 70px;
    font-family: MicrosoftYaHei-Bold;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 12px;
    color: #ffffff;
    text-align: center;
  }
  .content {
    padding: 0 300px;
    padding-top: 50px;
    position: relative;
    margin: 56px auto 0;
    border-radius: 20px;
    width: 459px;
    height: 310px;
    background-color: #fff;
    .login {
      background-color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      padding: 60px 70px 75px;
      p {
        font-size: 30px;
        font-weight: normal;
        color: #477bee;
        text-align: center;
        letter-spacing: 4px;
      }
    }
  }
  .el-input {
    width: 460px;
    margin-top: 20px;
    margin-bottom: 6px;
  }
}

.btnlogin {
  margin: 0 auto;
  width: 300px;
  margin-top: 20px;
  background-image: linear-gradient(-90deg, #286efd 0%, #3abcff 100%);
}
.el-input/deep/.el-input__icon {
  color: #105bf0;
}
</style>

